
<div class="auth-container">
    <div class="" >
      <div  >
        <router-outlet></router-outlet>
      </div>
      <!--  <div class="right" fxFlex >-->
      <!--  </div>-->
    </div>
  </div>
