import {Component, AfterViewInit, ViewChild, ElementRef, OnInit, NgZone} from "@angular/core";
import {Router} from "@angular/router";
import * as Handlebars from "handlebars";
import {ApiService} from "src/app/services";
import {SessionService} from "../../../organisation/services/session.service";
import { DatePipe } from '@angular/common';


interface CustomMarker extends google.maps.Marker {
  isEndpointAsset: boolean;
}

@Component({
  selector: 'app-map-implementation',
  templateUrl: './map-implementation.component.html',
  styleUrls: ['./map-implementation.component.css']
})
export class MapImplementationComponent implements OnInit {

  loading = false;
  mapMarkers: CustomMarker[] = [];
  allAssets!: any;
  @ViewChild("mapContainer", {static: false}) gmap!: ElementRef;
  map!: google.maps.Map;
  lat = -17.82922;
  lng = 31.05222;
  datepipe: DatePipe = new DatePipe('en-ZW')
  customStyles = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{visibility: 'off'}],
    }
  ];

  constructor(
    private service: ApiService,
    private router: Router,
    private sessionService: SessionService
  ) {
  }

  ngOnInit() {

    this.getCurrentLocation();
    this.getAllAssets();
    // setInterval(() => {
    //   this.getAllAssets();
    // }, 100);
  }

  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    styles: this.customStyles,
    zoom: 12,
    tilt: 45
  };


  ngAfterViewInit(): void {
    this.mapInitializer();
  }

  mapInitializer(): void {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.loadAllMarkers();
  }


  loadAllMarkers(): void {
    this.mapMarkers.filter(marker => !marker.isEndpointAsset).forEach(marker => marker.setMap(null));


    this.mapMarkers = this.mapMarkers.filter(marker => marker.isEndpointAsset);

    if (this.allAssets) {
      console.log("Assets Implementation")
      this.allAssets.forEach((asset: {
        latitude: any;
        longitude: any;
        id: any;
        name: any;
        online: boolean;
        description:any;
        region: any;
        lastDateTime: any;
      }) => {

        if (asset) {
          console.log(asset.name)

          let formattedDate = this.datepipe.transform(asset.lastDateTime, 'dd-MMM-YYYY HH:mm:ss','UTC+2')
 
          const templateScript = `
              <h2 style="color: #333;font-size: 20px; margin-bottom: 10px;">${asset.name}</h2>
              <p style="color: #666;margin: 5px 0;display: flex;align-items: center;"><i style="margin-right: 10px;" class="fas fa-check-circle"></i><strong>  State: </strong> ${asset.online}</p>
              <p style="color: #666;margin: 5px 0;display: flex;align-items: center;"><i style="margin-right: 10px;" class="fas fa-globe"></i><strong>  Region: </strong> ${asset.description}</p>
              <p style="color: #666;margin: 5px 0;display: flex;align-items: center;"><i style="margin-right: 10px;" class="fas fa-clock"></i><strong>  Time: </strong> ${formattedDate}</p>

              <button id="infoWindowBtn">
                <i  class="fas fa-plus-circle"></i> View More
               </button>
            `;

          const infoWindowTemplate = Handlebars.compile(templateScript);


          const iconUrl = asset.online
            ? 'https://api.zodsat.io/images/Transfomer_Green_Black.svg'
            : 'https://api.zodsat.io/images/Transformer_Red_Black.svg';

          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(asset.latitude, asset.longitude),
            map: this.map,
            title: asset.name,
            icon: {
              url: iconUrl,
              scaledSize: new google.maps.Size(40, 40)
            },
            optimized: true,
            animation: google.maps.Animation.DROP
          });
          const infoWindow = new google.maps.InfoWindow({
            content: infoWindowTemplate(null)
          });

          google.maps.event.addListener(marker, 'click', () => {
            infoWindow.open(this.map, marker);
          });

          infoWindow.addListener('domready', () => {

            const clickBtn = document.getElementById('infoWindowBtn')

            if (clickBtn) {
              clickBtn.addEventListener('click', () => {
                this.sessionService.saveAsset(asset.id);
                this.router.navigate(['home/organisation/virtual-asset']);
              })
            }
          })
          marker.setMap(this.map);
        }
      });
    }

  }

  getCurrentLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.coordinates = new google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );
      });
    }
  }


  getAllAssets() {
    this.service.getFromUrl(`/v1/core/assets/list`)   
      .subscribe((response: any) => {
        this.allAssets = response;
        this.loadAllMarkers();
      });
  }
}
