import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { LayoutRoutingModule } from './layout-routing.module';
import { ContainerComponent } from './components/container/container.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { NzModalModule } from 'ng-zorro-antd/modal';

@NgModule({
  declarations: [
    ContainerComponent,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    SharedModule,
    NzPopoverModule,
    NzModalModule
  ]
})
export class LayoutModule { }
