import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient, private location: Location) { }

  public login(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded;'
    );

    const body = new HttpParams()
      .set('email', email)
      .set('password', password);

    return this.http.post(`http://10.132.195.70:8004/api/v1/auth/authenticate`, body);
  }

  public logout() {
    sessionStorage.clear();
    this.location.replaceState('/');
    window.location.reload();
  }

  public getIsLogged(): boolean {
    return (localStorage.getItem('token') != null);
  }

  public getUsername() {
    if (this.getIsLogged()) {
      const token = localStorage.getItem('token');
      const payload = token!.split('.')[1];
      const payloadDecodedJson = atob(payload);
      const payloadDecoded = JSON.parse(payloadDecodedJson);
      return payloadDecoded.preferred_username;
    }
  }

  public getIsAdmin() {
    const token = localStorage.getItem('token');
    const payload = token!.split('.')[1];
    const payloadDecodedJson = atob(payload);
    const payloadDecoded = JSON.parse(payloadDecodedJson);
    return payloadDecoded.realm_access.roles.indexOf('ADMIN') !== -1;
  }
}
