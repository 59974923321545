import {InjectionToken, CUSTOM_ELEMENTS_SCHEMA, NgModule, LOCALE_ID} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { ApiService } from './services';
import { AuthGuard } from './shared/auth-guard';
import { JwtModule } from '@auth0/angular-jwt';
import { LayoutModule } from './libs/layout/layout.module';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { GoogleMapsModule } from '@angular/google-maps';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { LoaderComponent } from './loader/loader.component';
import {ZohoChatComponent} from "./zoho-chat/zoho-chat.component";
import { FlexLayoutModule } from '@angular/flex-layout';


export const APP_ENVIRONMENT = new InjectionToken<any>('environment');
registerLocaleData(en);

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent,
        LoaderComponent,
        ZohoChatComponent,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    SharedModule.forRoot({ environment: environment.baseUrl, production: environment.production }),
    NzMenuModule,
    NzButtonModule,
    NzPopoverModule,
    LayoutModule,
    NzModalModule,
    NzPaginationModule,
    FlexLayoutModule
  ],
  providers: [
    { provide: 'BaseUrl', useValue: environment.baseUrl },
    { provide: LOCALE_ID, useValue: 'en-ZW' },
    { provide: APP_ENVIRONMENT, useValue: environment },
    ApiService,  AuthGuard,
    {provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

 }
