import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { log } from 'handlebars';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTableFilterFn, NzTableFilterList } from 'ng-zorro-antd/table';
import { Observable, interval, takeWhile } from 'rxjs';
import { AssetCreateNewDeviceComponent } from 'src/app/libs/organisation/components/asset-create-new-device/asset-create-new-device.component';
import { DataService } from 'src/app/libs/organisation/services/data.service';
import { DialogService } from 'src/app/libs/organisation/services/dialog.service';
import { ApiService } from 'src/app/services';
import { AlertService } from 'src/app/services/alert.service';

export interface Data {
  id: number;
  name: string;
  age: number;
  address: string;
  disabled: boolean;
}
@Component({
  selector: 'app-rendered-assets-data',
  templateUrl: './rendered-assets-data.component.html',
  styleUrls: ['./rendered-assets-data.component.css']
})
export class RenderedAssetsDataComponent {


  allAssets:any= {
    content: [],
    pageable: {},
    last: false,
    totalPages: 0,
    totalElements: 0,
    size: 0,
    number: 0,
    sort: {},
    first: false,
    numberOfElements: 0,
    empty: false
  };
  loading=true;
  private alive = true;
  currentPageIndex = 1;
  currentPageSize = 10;
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly Data[] = [];
  setOfCheckedId = new Set<number>();
  totalControllersInOrganization!: any;
  deviceId!:any;
  currentPage = 0;
  pageSize = 1000;
  url:any
  form!: FormGroup;
  error: boolean =false
  baseUrl!: string;
  expression= 'null';
  workspaceIds: string[] = ['29222d0f3ba05678'];
  datepipe: DatePipe = new DatePipe('en-ZW')

  constructor(
    private router: Router,
    private service: ApiService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private modalService: NzModalService,
    private logService: DialogService,
    private nzMessageService: NzMessageService
    ){}

    ngOnInit() {
      this.getPaginatedAssets();

      // interval(1000)
      // .pipe(takeWhile(() => this.alive))
      // .subscribe(() => {
      //   this.getPaginatedAssets();
      // });
    }

  navigateToTopNav(asset:any) {
    this.dataService.updateAssetsData(asset);
    sessionStorage.setItem('userData', asset.id)
    sessionStorage.setItem('armedState', asset.armed)
    this.router.navigate(['home/organisation/virtual-asset']);
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly Data[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }


   // Filter functions
   nameFilter: NzTableFilterFn<string> | null = null;
   ageFilter: NzTableFilterFn<number> | null = null;

   // Filter lists
   listOfNameFilter: NzTableFilterList = [];
   listOfAgeFilter: NzTableFilterList = [];


  createDeviceDrawerVisible = false;
  bulkImportDrawerVisible = false;

  openCreateDeviceDrawer(): void {
    this.createDeviceDrawerVisible = true;
  }

  closeCreateDeviceDrawer(): void {
    this.createDeviceDrawerVisible = false;
  }

  openBulkImportDrawer(): void {
    this.bulkImportDrawerVisible = true;
  }

  closeBulkImportDrawer(): void {
    this.bulkImportDrawerVisible = false;
  }

  getPaginatedAssets() {
    const requestData = {
      "expression": null,
      "workspaceIds": this.workspaceIds,
    };

    const pageSize = this.currentPageSize;
    const startIndex = this.currentPageIndex - 1;
    const paginationParams = `page=${startIndex}&size=${pageSize}`;

    this.service.getFromUrl(`/v1/core/assets?${paginationParams}`)
      .subscribe((response: any) => {
        this.loading=false;
        this.allAssets = response;
      });
  }

  getAssetState(status: boolean): string {
    switch (status) {
      case true:
        return 'lightgreen';
      case false:
        return 'red';
      default:
        return 'grey';
    }
  }



getBackgroundColor(status: string): string {
  switch (status) {
    case 'OFFLINE':
      return 'red';
    case 'ONLINE':
      return 'lightgreen';
    case 'INACTIVE':
      return 'grey';
    default:
      return 'transparent';
  }
}


edit(asset: any): void {
  const selectedItem = asset;
  const modalRef: NzModalRef = this.modalService.create({
    nzTitle: 'Edit Device',
    nzContent: AssetCreateNewDeviceComponent,
    nzData: { data: selectedItem } as any,
    nzFooter: null,
  });

  this.logService.updateModalData(selectedItem);
}

cancel(): void {
  this.nzMessageService.info('Cancelled');
}

confirm(): void {
  this.nzMessageService.info('click confirm');
}

beforeConfirm(): Observable<boolean> {
  return new Observable(observer => {
    setTimeout(() => {
      observer.next(true);
      observer.complete();
    }, 3000);
  });
}

handlePageIndexChange(pageIndex: number): void {
  this.currentPageIndex = pageIndex;
  this.getPaginatedAssets();
}

handlePageSizeChange(pageSize: number): void {
  this.currentPageSize = pageSize;
  this.getPaginatedAssets();
}

convertDateToGmt(dateTime: any){
  return this.datepipe.transform(dateTime, 'dd-MM-YY HH:mm:ss','UTC+2')
  }
}
