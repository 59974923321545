<div *ngIf="loading; else content" class="loader-container">
  <img class="loader" src="assets/favicon1.png" height="100px" width="100px" alt="Loader Image" />
</div>

<ng-template #content>
<div class="center-container">
  <div class="center-content">
    <nz-drawer
      [nzClosable]="false"
      [nzVisible]="createDeviceDrawerVisible"
      nzPlacement="right"
      nzTitle="Create new Device"
      [nzWidth]="900"
      (nzOnClose)="closeCreateDeviceDrawer()"
    >
      <!-- <ng-container *nzDrawerContent>
       <app-add-new-rule></app-add-new-rule>
      </ng-container> -->
    </nz-drawer>

    <!-- Bulk Import Drawer -->
    <nz-drawer
      [nzClosable]="false"
      [nzVisible]="bulkImportDrawerVisible"
      nzPlacement="right"
      nzTitle="Bulk Import"
      [nzWidth]="900"
      (nzOnClose)="closeBulkImportDrawer()"
    >
      <!-- <ng-container *nzDrawerContent>
      <app-bulk-import></app-bulk-import>
      </ng-container> -->
    </nz-drawer>
  </div>
</div>
<nz-table
 #columnTable
 [nzData]="allAssets?.content"
 [nzScroll]="{ x: 'max-content' }"
 [nzLoading]="loading"
 [nzFrontPagination]="false"
 [nzPageIndex]="currentPageIndex"
 [nzTotal]="allAssets?.totalElements"
 (nzPageIndexChange)="handlePageIndexChange($event)"
 (nzPageSizeChange)="handlePageSizeChange($event)"
 [nzPageSize]="currentPageSize"
 nzShowSizeChanger
  >
  <thead>
    <tr>
      <th nzLeft>Name</th>
      <th nzLeft>State</th>
      <th nzLeft>Timestamp</th>
      <th>Armed</th>
      <th>Siren</th>
      <th>Power</th>
      <th>Contact1</th>
      <th>Contact2</th>
      <th>Contact3</th>
      <th>Contact4</th>
      <th>Pir1</th>
      <th>Pir2</th>
      <th>Pir3</th>
      <th>Vibration</th>
      <th>Temperature</th>
      <th>Latitude</th>
      <th>Longitude</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let asset of allAssets?.content">
    <td nzLeft>
        <a (click)="navigateToTopNav(asset)">{{ asset.name }}</a>
      </td>
      <td nzLeft> <span [ngStyle]="{'display': 'inline-block', 'width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': getAssetState(asset.online)}"></span></td>
      <td nzLeft>
      {{ convertDateToGmt(asset.lastDateTime)}}
      </td>
      <td> <span [ngStyle]="{'display': 'inline-block', 'width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': asset.armed ? 'lightgreen':'red'}"></span></td>
      <td> <span [ngStyle]="{'display': 'inline-block', 'width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': asset.siren ? 'red':'lightgreen'}"></span></td>
      <td> <span [ngStyle]="{'display': 'inline-block', 'width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': asset.power ? 'lightgreen':'red'}"></span></td>
      <td> <span [ngStyle]="{'display': 'inline-block', 'width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': getBackgroundColor(asset.contact1)}"></span></td>
      <td> <span [ngStyle]="{'display': 'inline-block', 'width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': getBackgroundColor(asset.contact2)}"></span></td>
      <td> <span [ngStyle]="{'display': 'inline-block','width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': getBackgroundColor(asset.contact3)}"></span></td>
      <td> <span [ngStyle]="{'display': 'inline-block', 'width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': getBackgroundColor(asset.contact4)}"></span></td>
      <td> <span [ngStyle]="{'display': 'inline-block', 'width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': getBackgroundColor(asset.pir1)}"></span></td>
      <td> <span [ngStyle]="{'display': 'inline-block', 'width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': getBackgroundColor(asset.pir2)}"></span></td>
      <td> <span [ngStyle]="{'display': 'inline-block', 'width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': getBackgroundColor(asset.pir2)}"></span></td>
      <td> <span [ngStyle]="{'display': 'inline-block', 'width': '20px', 'height': '20px', 'border-radius': '50%', 'background-color': getBackgroundColor(asset.pir3)}"></span></td>
     <td>{{asset.temperature}}</td>
     <td>{{asset.longitude}}</td>
     <td>{{asset.latitude}}</td>
     </tr>
  </tbody>
</nz-table>
</ng-template>
