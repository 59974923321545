

<div class="container">
  <nz-collapse nz-row [nzGutter]="8" style="background-color: rgb(205, 203, 203);">
    <div nz-col [nzSpan]="8">
      <p><span style="color: blue; font-size: 30px; padding-top: 40px;" nz-icon  nzTheme="outline"></span><img src="/assets/sea-sight.png" height="40px" style="padding-top: 5px;"><span style="font-size: 15px; font-weight: bold;">  Transformer Security Monitoring</span></p>
    </div>
    <div nz-col [nzSpan]="4" style="text-align: right;">
      <p><span style="color: red; font-size: 20px; padding-top: 21px;" nz-icon nzType="alert" nzTheme="outline"></span> <span style="font-size: 15px; font-weight: bold;"> {{healthData.inAlarmMode}} In Alarm Mode</span></p>
    </div>
    <div nz-col [nzSpan]="4" style="text-align: right;">
      <p><span style="color: maroon; font-size: 20px; padding-top: 21px;" nz-icon nzType="hourglass" nzTheme="outline"></span> <span style="font-size: 15px; font-weight: bold;"> {{healthData.assetsInActive}} Offline</span></p>
    </div>
    <div nz-col [nzSpan]="4" style="text-align: right;">
      <p><span style="color: green; font-size: 20px; padding-top: 21px;" nz-icon nzType="check-circle" nzTheme="outline"></span> <span style="font-size: 15px; font-weight: bold;" >  {{healthData.assetsOnline}} Online</span></p>
    </div>
    <div nz-col [nzSpan]="2" style="text-align: right;">
      <p>
        <span
          style="font-size: 30px; padding-top: 21px;"
          nz-icon
          [nzType]="isPanelOpen ? 'down' : 'right'"
          nzTheme="outline"
          (click)="openNzCollapsePanel()"
        ></span>
      </p>
    </div>


    <div nz-col [nzSpan]="24">
      <nz-collapse-panel
        *ngFor="let panel of panels"
        [nzHeader]="panel.name"
        [nzActive]="panel.active"
        [nzDisabled]="panel.disabled"
        [nzShowArrow]="false"
      >
        <app-rendered-assets-data></app-rendered-assets-data>
      </nz-collapse-panel>
    </div>
  </nz-collapse>
</div>
