import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private closeModalSubject = new Subject<void>();
  closeDrawer$ = this.closeModalSubject.asObservable();

  closeModal() {
    this.closeModalSubject.next();
  }
}