
<form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>Password</nz-form-label>
      <nz-form-control [nzSpan]="24" nzValidatingTip="Validating...">
        <input type="password" nz-input formControlName="password" placeholder="Add Password" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzValidatingTip="Validating...">
        <input type="password" nz-input formControlName="confirmPassword" placeholder="Add Password" />
        <div *ngIf="confirmPasswordControl?.hasError('passwordMismatch')" class="error-message">
         <span style="color: red; font-style: italic; font-size: 10px;">Password and Confirm Password do not match</span>
        </div>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzSpan]="24" [nzOffset]="0">
        <div class="submit" style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 5px;">
          <button nz-button nzType="primary" type="submit" style="background-color: blue; color: white; text-align: center;" [disabled]="!form.valid">Submit</button>
          <button nz-button (click)=dismiss()  nzGhost type='reset' style="background-color: red; color: white; text-align: center;" (click)="dismiss()">Cancel</button>
        </div>
      </nz-form-control>
    </nz-form-item>
  </form>
