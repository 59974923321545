import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ApiService } from 'src/app/services';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {

  @Input() data: any;
  @Output() add = new EventEmitter<string>();
  form!: FormGroup;
  isCollapse = true;
  ruleData: any;
  visible = true;

  constructor(
    private formBuilder: FormBuilder,
    private service: ApiService,
    private notification: AlertService,
    private modalService: ModalService
  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      username: [''],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, { validator: this.passwordMatchValidator });
  }

  passwordMatchValidator(form: FormGroup) {
    const passwordControl = form.get('password');
    const confirmPasswordControl = form.get('confirmPassword');

    if (passwordControl && confirmPasswordControl) {
      const password = passwordControl.value;
      const confirmPassword = confirmPasswordControl.value;

      if (password !== confirmPassword) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }



  toggle(visible: boolean): void {
    this.visible = visible;
  }

  submitForm(): void {

    const user = JSON.parse(sessionStorage.getItem('user') ?? '{}');
    const constantUsername = user.email;

    if (this.form.valid) {
      const formData = { ...this.form.value, username: constantUsername };
      this.service.postToUrl(`/core/v1/users/change-password`, formData)
        .subscribe({
          next: () => {
            this.notification.showSuccess('Saved Successfully');
          },
          error: (error) => {
            console.error('Error saving data:', error);
          },
        });
    } else {
      this.showError();
    }

    this.modalService.closeModal();
  }


  dismiss(){
    this.modalService.closeModal();
  }

  showSuccess() {
    this.notification.showSuccess('Changed successfully');
  }

  showError() {
    this.notification.showError('The password and confirm password are not the same');
  }

  get confirmPasswordControl(): AbstractControl | null {
    return this.form.get('confirmPassword');
  }
}
