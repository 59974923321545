import { Injectable, Injector } from '@angular/core';
import { Observable, throwError, EMPTY, Subject } from 'rxjs';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AuthService } from "./auth.service";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(
              private notification: AlertService,
              private authService:AuthService
            ) {

  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.getToken()) {
      request = this.addAuthHeader(request);
    }
    return next.handle(request).pipe(
      catchError((error: any) => this.handleResponseError(error)));
  }

  private addAuthHeader(req: HttpRequest<any>) {
    return  req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`
      }
    });
  }

  handleResponseError(error: any) {


    if (error instanceof HttpErrorResponse) {

      if (!navigator.onLine) {
        this.notification.showNetworkError();
        return throwError(error);
      }

      console.info('Status: '+(<HttpErrorResponse>error).status)

      switch ((<HttpErrorResponse>error).status) {
        case 0:
          break;
        case 401:
          if(error.error.message) {
            this.notification.showUnathorizedEntry(error.error.message);
          }else{
            this.logoutUser();
            this.notification.showForbiddenEntry('Session Expired, Please login again');
          }
          break;
        case 403:
          //this.logoutUser();
          this.notification.showForbiddenEntry(
            error.error.message ? error.error.message : 'Session Expired, Please login again'
          );
          break;
        case 400:
        case 404:
        case 500:
        case 503:
        default:
          this.notification.showError( error.error.message ? error.error.message : 'An error has occurred');
          return throwError(error);
      }
    }
    return throwError(error);
  }

  logoutUser() {
    this.authService.logout();
    return EMPTY;
  }

}
