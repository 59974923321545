<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar"
            nzCollapsible
            [nzWidth]="isCollapsed ? '0px' : '256px'"
            nzBreakpoint="md"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">

    <div *ngIf="!isCollapsed" class="sidebar-logo">
      <a href="https://www.powertel.co.zw/" target="_blank">
        <img src="/assets/PowerTel logo FC.svg" style="width:170px; height: auto;">
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-menu-item><i nz-icon nzType="man"></i><span nz-popover
                                                         nzPopoverTitle="Assistant"
                                                         nzPopoverPlacement="rightTop"
                                                         nzTitle="Onboarding"
                                                         nzIcon="block"
                                                         [nzPopoverContent]="contentTemplate">Onboarding</span></li>
      <ng-template #contentTemplate>
        <div>
          <p><a routerLink="/home/onboarding/create-device">1. Create Device</a></p>
          <p>2. Bulk Import</p>
          <p>3. Add a Gateway </p>
        </div>
      </ng-template>
      <li nz-submenu nzOpen nzTitle="Dashboard" nzIcon="dashboard">
        <ul>
          <li nz-menu-item routerLink='/home/map/google-maps' nzIcon="weibo-circle"><span nz-icon nzType="fund-view"
                                                                                          nzTheme="outline"></span>
            Overview
          </li>
          <li nz-menu-item routerLink='/home/organisation/assets' nzIcon="assets"><span nz-icon nzType="codepen-circle"
                                                                                        nzTheme="outline"></span> Assets
          </li>
          <li nz-menu-item routerLink='/home/charts/asset'><span nz-icon nzType="bar-chart" nzTheme="outline"></span>
            Analytics
          </li>
          <li nz-menu-item routerLink='/home/rules/rule'><span nz-icon nzType="warning" nzTheme="outline"></span> Rules
          </li>
          <li nz-menu-item routerLink='/home/alarms/alarm'><span nz-icon nzType="alert" nzTheme="outline"></span> Alarms
          </li>
          <li nz-menu-item routerLink='/home/logs/data-processing-logs' nzIcon="logs"><span nz-icon nzType="monitor"
                                                                                            nzTheme="outline"></span>
            Logs
          </li>
          <li nz-menu-item routerLink='/home/locations/active-locations' nzIcon="map"><span nz-icon nzType="environment"
                                                                                            nzTheme="outline"></span>
            Locations
          </li>
        </ul>
      </li>



      <li nz-submenu nzOpen nzTitle="Reports" nzIcon="snippets">
        <ul>
          <li nz-menu-item routerLink='/home/reports/assets-reports'><span nz-icon nzType="subnode"
                                                                           nzTheme="outline"></span> End Nodes
          </li>
          <li nz-menu-item routerLink='/home/reports/gateways-reports'><span nz-icon nzType="gateway"
                                                                           nzTheme="outline"></span> Gateways
          </li>
          <li nz-menu-item routerLink='/home/reports/alarms-reports'><span nz-icon nzType="bell"
                                                                           nzTheme="outline"></span> Alarms
          </li>
          <li nz-menu-item routerLink='/home/reports/audits-reports'><span nz-icon nzType="audit"
                                                                           nzTheme="outline"></span> Audits
          </li>
          <li nz-menu-item routerLink='/home/reports/users-reports'><span nz-icon nzType="team"
                                                                          nzTheme="outline"></span> Users
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="User Management" nzIcon="user-add">
        <ul>
          <li nz-menu-item routerLink='/home/users/user'><span nz-icon nzType="usergroup-add" nzTheme="outline"></span>
            Users
          </li>
          <li nz-menu-item routerLink='/home/users/group'><span nz-icon nzType="team" nzTheme="outline"></span> Groups
          </li>
          <li nz-menu-item routerLink='/home/users/permission'><span nz-icon nzType="key" nzTheme="outline"></span>
            Permissions
          </li>
        </ul>
      </li>
      <li nz-menu-item><i nz-icon nzType="user"></i><span nz-popover
        nzPopoverTitle="User Profile"
        nzPopoverPlacement="rightTop"
        [(nzPopoverVisible)]="visible"
        nzTitle="User Profile"
        nzIcon="block"
        [nzPopoverContent]="ngTemplate">My Profile</span></li>
<ng-template #ngTemplate>
<div>
<p>
<span style="font-weight: 1000;">{{preferredName }}</span> <br>
<span style="font-weight: lighter;">{{region}}</span> <br>
<span style="font-weight: lighter;">{{jobTitle}}</span><br>
<span style="font-weight: lighter;">{{userName}}</span>
</p>
<hr style="width: 100%; margin-top: 10px; margin-bottom: 10px;">
<p>
<span> <button (click)="showModal()"
class="workspace">Change Password
</button></span>
</p>

<hr style="width: 100%; margin-top: 10px; margin-bottom: 10px;">
<p>
<span style="font-size: 15px; padding-right: 10px;" nz-icon nzType="logout" nzTheme="outline"></span>
<span class="logout" (click)="logout()">Logout</span> <br>
</p>

</div>
</ng-template>

    </ul>
  </nz-sider>
  <nz-layout>

    <nz-header>
      <div class="app-header">
    <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
      <span class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>
    </span>
        <div class="logo-container">
          <img src="/assets/ZETCD logo FC.svg" class="responsive-image">
        </div>
      </div>
    </nz-header>


    <nz-content style="margin:24px 16px 0;">
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <nz-footer style="text-align: center;">Copyright © 2023-2024 TAIS Solution Designed & Maintained by ZODSAT IoT</nz-footer>
  </nz-layout>
</nz-layout>


<nz-modal
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="250"
>
  <ng-template #modalTitle><span style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Change your password</span>
  </ng-template>

  <ng-template #modalContent>
    <app-change-password></app-change-password>
  </ng-template>

  <ng-template #modalFooter>
  </ng-template>
</nz-modal>

