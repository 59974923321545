import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements OnInit {
  loading = true;
  healthData: any;
  private alive = true;

  constructor(private fb: FormBuilder, private service: ApiService) {}

  switchValue = false;
  validateForm: FormGroup = this.fb.group({
    note: this.fb.control<string | null>(null, Validators.required),
    gender: this.fb.control<'male' | 'male' | null>(null, Validators.required)
  });

  submitForm(): void {
    if (this.validateForm.valid) {

    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  onChange(result: Date): void {

  }

  ngOnInit() {
    this.getHealthyStatus();


    // interval(1000)
    //   .pipe(takeWhile(() => this.alive))
    //   .subscribe(() => {
    //     this.getHealthyStatus();
    //   });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  getHealthyStatus() {
    this.service.getFromUrl(`/v1/core/assets/health`).subscribe((response: any) => {
      this.healthData = response;
      this.loading = false;
    });
  }
}

