import {Inject, Injectable} from '@angular/core';
import { DefaultService } from './default.service';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends DefaultService<any>{
  constructor(httpClient: HttpClient, @Inject('BaseUrl') baseUrl: string) {
    super(httpClient, baseUrl);
  }
}
