import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ModalService } from '../../services/modal.service';
import {ApiService} from "../../../../services";

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class ContainerComponent  implements OnInit {
  isVisible = false;
  vis: boolean  = false;
  visible:  boolean = false;
  isCollapsed = false;
  userName!:any;
  preferredName!:any;
  region!:any;
  jobTitle!:any;
  userRole!:any;

  user:any= {
    username: String,
    name:String,
    title: String,
    mobile:String,
    region:String,
    group:{}
  };


  ngOnInit(){
  this.getUserCredentials();
  }

  change(value: boolean): void {

  }
  mantainStatus(){
  this.vis = true;
  }


  getUserCredentials(){
    this.service.getFromUrl(`/cmn/v1/users/profile`)
      .subscribe((response: any) => {
        this.user = response;
        this.userRole=  response.group.name;
        this.userName =  response.username;
        this.preferredName  = response.name;
        this.jobTitle = response.title;
        this.region = response.region;
        sessionStorage.setItem('user', JSON.stringify(this.user));
      });

    this.userRole=  this.user.group.name;
    this.userName =  this.user.username;
    this.preferredName  = this.user.name;
    this.jobTitle = this.user.title;
    this.region = this.user.region;
  }

  constructor(
    private loginService: LoginService,
    private router:Router,
    private modalService: ModalService,
    private service:ApiService
  ){
    this.modalService.closeDrawer$.subscribe(() => {

      this.closeModal();
    });
  }

  closeModal(){
    this.isVisible = false;
  }

  logout() {
    this.loginService.logout();
  }

  navigateToDataOverview() {
    this.router.navigate(['home/organisation/virtual-asset']);
  }
  navigateToOrganizationOverview(){
    this.router.navigate(['home/organisation/organisation-overview']);
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}
