<div *ngIf="!isContentLoaded; else content" class="loader-container">
  <img class="loader" src="assets/images/throbber.gif" height="100px" width="100px" alt=""/>
</div>

<ng-template #content>
  <body>
  <section class="container">
    <img src="/assets/PowerTel logo FC.svg" style="height: 80px; padding-bottom: 20px;">
    <form (ngSubmit)="login()" class="form" nz-form [formGroup]="form">
      <label for="userId"><span> Email </span></label>
      <input type="text" nz-input formControlName="username" id="userId">
      <label for="password"><span> Password </span></label>
      <input type="password" nz-input formControlName="password" id="password">
      <button nz-button nzType="primary" (click)="loadTwo()" [nzLoading]="isLoadingTwo" class="centered-button">Login</button>

    </form>
    <p class="newUser">
      <span class="register" (click)="visitForgotPage()">Forgot password?</span>
    </p>
    <p class="footer-note">
      Copyright © 2023-2024 TAIS Solution Designed & Maintained by
      <a href="https://www.zodsat.com">ZODSAT IoT</a>
    </p>
  </section>
  </body>
</ng-template>
