// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private assetsData = new BehaviorSubject<any>(null);
  currentAssetsData = this.assetsData.asObservable();

  updateAssetsData(data: any) {
    this.assetsData.next(data);
  }
}
