import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {Observable, empty, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { saveAs } from 'file-saver';


export class DefaultService<T> {

  constructor(public defaultHttpClient: HttpClient, public baseUrl: string) { }

  public getAll(): Observable<T[]> {
    const url = `/all`;
    return this.getFromUrl(url);
  }

  public getFromUrl(url: string): Observable<any> {
    return this.defaultHttpClient.get(`${this.baseUrl}${url}`)
      .pipe(catchError(this.formatErrors));
  }


  public get(id: number | string): Observable<T> {
    return this.getFromUrl(`/${id}`);
  }

  public post(body: T): Observable<any> {
    return this.postToUrl('', body);
  }

  public patchToUrl(url: string, body: any): Observable<any> {
    return this.defaultHttpClient.patch(`${this.baseUrl}${url}`, body, {
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError(this.formatErrors)
    );
  }



  public postToUrl(url: string, body: any): Observable<any> {
    return this.defaultHttpClient.post(`${this.baseUrl}${url}`, body, {
      observe: 'response'
    }).pipe(map((response: HttpResponse<any>) => response.body),catchError(this.formatErrors));
  }

  public getWithBody(url: string, body: any): Observable<any> {
    const options = {
      params: body // Assuming body is an object that you want to send as query parameters
    };
    return this.defaultHttpClient.get(`${this.baseUrl}${url}`, options)
      .pipe(
        map((response: any) => response), // Corrected to map directly to the response
        catchError(this.formatErrors)
      );
  }


  public update(body: T): Observable<any> {
    return this.updateToUrl('', body);
  }

  public putToUrl(url: string): Observable<any> {
    return this.defaultHttpClient.put(`${this.baseUrl}${url}`,null , {
      observe: 'response'
    }).pipe(map((response: HttpResponse<any>) => response.body));
  }


  public updateToUrl(url: string, body: any): Observable<any> {
    return this.defaultHttpClient.put(`${this.baseUrl}${url}`, body, {
      observe: 'response'
    }).pipe(map((response: HttpResponse<any>) => response.body));
  }

  public delete(url: string): Observable<any> {
    return this.defaultHttpClient.delete(`${this.baseUrl}${url}`, {
      observe: 'response'
    }).pipe(map((response: HttpResponse<any>) => response.body));
  }

  public getReport(url: string): Observable<Blob> {
    return this.defaultHttpClient.get(`${this.baseUrl}${url}`, {
      responseType: 'blob'
    });
  }

  public search(parameter: any): Observable<T> {
    return this.getFromUrl(`?${parameter}`);
  }

  private formatErrors(error: Error) {
    console.log('Entered into error '+JSON.stringify(error))
    return throwError(error);
  }

}
