import { Component } from '@angular/core';

declare var $zoho: any;

@Component({
  selector: 'app-zoho-chat',
  templateUrl: './zoho-chat.component.html',
  styleUrls: ['./zoho-chat.component.css']
})
export class ZohoChatComponent {

  ngOnInit(): void {
    // Initialize Zoho SalesIQ
    $zoho.salesiq.ready(() => {
      $zoho.salesiq.visitor.trigger('openChat');
    });
  }

}
