import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root' // It will inject this provider at the root level of the application so it can be accessed anywhere.
})
export class AlertService {
  constructor(private toastrService: NzNotificationService) {}

  showSuccess(message?: string) {
    const msg = message === undefined? 'Operation completed successfully': message;
    this.toastrService.success( 'Success',msg);
  }

  showError(message: string) {
    this.toastrService.error('Error',message);
  }

  showForbiddenEntry(message: string){
    this.toastrService.warning('Forbidden', message)
  }


  showUnathorizedEntry(message?: string){
    const msg = message === null ? 'Session Expired, Please Login Again': message;
    if (msg != null) {
      this.toastrService.info('Forbidden', msg)
    }
  }

  showSuccessEntry(message: string){
    this.toastrService.info('Success','Welcome to Transformer Monitoring System')
  }

  showNetworkError() {
    this.toastrService.error('Error','Failed to connect, please check your internet connection');
  }

}
