import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services';
import { AlertService } from 'src/app/services/alert.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/libs/organisation/services/data.service';
import { DialogService } from 'src/app/libs/organisation/services/dialog.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-assets-data',
  templateUrl: './assets-data.component.html',
  styleUrls: ['./assets-data.component.css']
})
export class AssetsDataComponent implements OnInit {

  panels = [
    {
      active: false,
      name: '',
      disabled: false
    },
  ];
  healthData!: any;
  isPanelOpen = false; // Variable to track the panel's open/close state

  constructor(
    private router: Router,
    private service: ApiService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private modalService: NzModalService,
    private logService: DialogService,
    private nzMessageService: NzMessageService
  ) {}

  ngOnInit() {
    this.getHealthyStatus();
  }

  getHealthyStatus() {   
    this.service.getFromUrl(`/v1/core/assets/health`)
      .subscribe((response: any) => {
        this.healthData = response;
      });
  }

  openNzCollapsePanel() {
    this.isPanelOpen = !this.isPanelOpen; // Toggle the panel's open/close state
    this.panels[0].active = this.isPanelOpen;
  }
}
