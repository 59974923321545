import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { DialogService } from '../../services/dialog.service';
import { ApiService } from 'src/app/services';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-asset-create-new-device',
  templateUrl: './asset-create-new-device.component.html',
  styleUrls: ['./asset-create-new-device.component.css']
})
export class AssetCreateNewDeviceComponent {

  data1: any;
  index = 0;
  disable = false;
  dataFlows: string[] = [];
  onIndexChange(index: number): void {
    this.index = index;
  }
  controlArray: Array<{ index: number; show: boolean }> = [];
  isCollapse = true;
  validateForm: FormGroup<{
    description: FormControl<string | null>;
    userName: FormControl<string | null>;
    gender: FormControl<'male' | 'male' | null>;
    tags: FormControl<string | null>;
    dataflow: FormControl<string | null>;
  }> = this.fb.group({
    description: this.fb.control<string | null>(null, Validators.required),
    gender: this.fb.control<'male' | 'male' | null>(null, Validators.required),
    userName: this.fb.control<string | null>(null, Validators.required),
    tags: this.fb.control<string | null>(null, Validators.required),
    dataflow: this.fb.control<string | null>(null, Validators.required)
  });


  submitForm() {
    this.service.updateToUrl(`/iot/v3/devices/02b0f5eae4a5ef38`, this.validateForm.value)
      .subscribe({
        next: () => {
          this.alertService.showSuccess('Saved Successfully');
          this.ngOnInit();
          // this.dismiss();
        }
      });
  }

  // dismiss(){
  //   this.dialogRef.close();
  // }



  ngOnInit() {
    this.logService.modalData$.subscribe((data) => {
      this.data1 = data;
      this.validateForm.controls.userName.setValue(this.data1.name);
      this.validateForm.controls.description.setValue(this.data1.description);
      this.validateForm.controls.tags.setValue(this.data1.tags.map((tag: { name: any; }) => tag.name).join(', '));
      this.validateForm.controls.dataflow.setValue(this.data1.dataFlow.name);
      this.dataFlows = [this.data1.dataFlow.name,];
    });
  }

  constructor(
    private fb: FormBuilder,
    private logService: DialogService,
    private service: ApiService,
    private alertService: AlertService,
    private modalRef: NzModalRef)
    {}
}
