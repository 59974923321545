import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  saveAsset(assetId: string) {
    sessionStorage.setItem('asset-data',assetId);
  }

  getAsset(){
    return sessionStorage.getItem('asset-data');
  }

  getArmedState(){
    return sessionStorage.getItem('armedState')=== "true";
  }
}
