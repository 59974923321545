import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { EnvironmentInterface, _environment } from '../models';
import { AuthService } from '../services';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { IconsProviderModule } from '../icons-provider.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import {TokenInterceptorService} from "../services/token-interceptor.service";


@NgModule({
  exports: [
    NzCardModule,
    FormlyModule,
    NzFormModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzCardModule,
    FormsModule,
    NzInputModule,
    NzMessageModule,
    FormsModule,
    HttpClientModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzButtonModule,
    NzPopoverModule,
    NzCardModule, NzButtonModule,
    NzCardModule,
    NzGridModule,
    NzIconModule,
    NzDrawerModule,
    NzStepsModule,
    NzSwitchModule,
    NzSelectModule,
    NzUploadModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzTableModule,
    NzCheckboxModule,
    NzTabsModule,
    NzResultModule,
    NzButtonModule,
    NzPopoverModule,
    NzButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NzInputModule,
    NzDrawerModule,
    NzCardModule,
    NzIconModule,
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzPopconfirmModule,
   CanvasJSAngularChartsModule,


  ],
  declarations: [],
  imports: [
    CommonModule,

    JwtModule.forRoot({
      config: { tokenGetter: token, skipWhenExpired: true }
    }),
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },

  ]
})
export class SharedModule {
  constructor(@Optional() @SkipSelf() parentModule: SharedModule) {
    throwIfAlreadyLoaded(parentModule, 'SharedModule');
  }

  static forRoot(config: EnvironmentInterface): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: _environment,
          useValue: config
        }
      ]
    };
  }
}
function throwIfAlreadyLoaded(parentModule: unknown, moduleName: string) {
  if (!parentModule) return;
  throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
}

export function token(): string {
  return AuthService.TOKEN;
}
