import { Component } from '@angular/core';
import { FormGroup,Validators,FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { _environment } from 'src/app/models/environment-url';
import { ApiService } from 'src/app/services';
import { AlertService } from 'src/app/services/alert.service';
import { AuthGuard } from 'src/app/shared/auth-guard';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  isLoadingTwo = false;
  isContentLoaded  = false;
  form!: FormGroup;
  error: boolean =false
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private guard: AuthGuard,
    private notification:AlertService,
    private service:ApiService
) { }

ngOnInit() {

  this.form = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  const backgroundImage = new Image();
  backgroundImage.src = '/assets/pilots.png';

  backgroundImage.onload = () => {
    setTimeout(() => {
      this.isContentLoaded = true;
    }, 3000);
  };
}

loadTwo(): void {
  this.isLoadingTwo = true;
  setTimeout(() => {
    this.isLoadingTwo = false;
  }, 5000);
}


get f() { return this.form.controls; }


login() {
  this.service.postToUrl(`/v1/authenticate/user`,this.form.value).subscribe({
    next: (response: any) => {
      if (response.jwtToken) {
        this.guard.service.saveToken(response.jwtToken);
        let returnUrl = this.route.snapshot.queryParams['returnUrl'];
        returnUrl = returnUrl !== 'undefined' ? returnUrl : '';
        this.notification.showSuccessEntry('');
        this.router.navigateByUrl(returnUrl || '/home');
        return;
      }
    },

  });
}


  visitForgotPage(){
    this.router.navigate(['/auth/forgot']);
  }
}
