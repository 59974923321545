<div *ngIf="loading; else content" class="loader-container">
  <img class="loader" src="assets/images/throbber.gif" height="100px" width="100px" alt="Loader Image" />
</div>

<ng-template #content>

<div class="container">
  <h2 style=" color:rgb(71, 71, 159);"> <span style="font-size: 35px; color: rgb(71, 71, 159);" nz-icon nzType="fund-view" nzTheme="outline"></span> Asset Health</h2>
</div>

  <div class="btn">
    <div class="button-row">
      <button class="in-alarm-mode" nz-button nzDanger>ALARM MODE</button>
      <button class="non-responsive" nz-button nzType="default" nzDanger>NON RESPONSIVE</button>
      <button class="inactive" nz-button nzType="default" nzDanger>INACTIVE</button>
      <button class="online" nz-button nzType="dashed" nzDanger>ONLINE</button>
      <button class="alarm-comments" nz-button nzType="default" nzDanger>ALARM COMMENTS</button>
      <button class="log-messages" nz-button nzType="default" nzDanger>DAILY LOGS</button>
    </div>
    <div class="button-row">
      <button class="alarm-mode-number" nz-button>{{healthData.inAlarmMode}}</button>
      <button class="non-responsive-number" nz-button nzType="default">{{healthData.nonResponsive}}</button>
      <button class="inactive-number" nz-button nzType="default">{{healthData.assetsInActive}}</button>
      <button class="online-number" nz-button nzType="dashed">{{healthData.assetsOnline}}</button>
      <button class="alarm-comments-number" nz-button nzType="default">{{healthData.alarmComments}}</button>
      <button class="log-messages-number" nz-button nzType="default">{{healthData.logMessages}}</button>
    </div>
  </div>

    <div style="padding-top: 20px;">
  <app-map-implementation></app-map-implementation>
    </div>

    <div style="padding-top: 20px;">
       <app-assets-data></app-assets-data>
    </div>
    </ng-template>





