import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './libs/auth/components/login/login.component';
import { ContainerComponent } from './libs/layout/components/container/container.component';
import { GoogleMapComponent } from './libs/google-maps/components/google-map/google-map.component';

const routes: Routes = [
  {
    path:'auth',
    loadChildren: ()=> import('./libs/auth/auth.module').then( a=> a.AuthModule)
  },
  {
    path:'',component:LoginComponent,
  },
  {
    path:'home',component:ContainerComponent,
    children:[
      {
        path:'',component:GoogleMapComponent
      },

      {
        path: 'locations',loadChildren: ()=> import('./libs/locations/locations.module').then(b => b.LocationsModule)
      },
      {
        path:'onboarding',loadChildren:()=> import('./libs/onboarding/onboarding.module').then(b =>b.OnboardingModule)
      },
      {
        path:'organisation',loadChildren:()=> import('./libs/organisation/organisation.module').then(b =>b.OrganisationModule)
      },
      {
        path:'logs',loadChildren:()=>import('./libs/logs/logs.module').then(b=>b.LogsModule)
      },
      {
        path:'map',loadChildren:()=> import('./libs/google-maps/google-maps.module').then( b =>b.GoogleMapsModule)
      },
      {
        path:'users',loadChildren:()=> import('./libs/users/users.module').then( b =>b.UsersModule)
      },
      {
        path:'regions',loadChildren:()=> import('./libs/region/region.module').then( b=>b.RegionModule)
      },
      {
        path:'alarms', loadChildren:()=> import('./libs/alarms/alarms.module').then(b=>b.AlarmsModule)
      },
      {
        path:'rules',loadChildren:()=> import('./libs/rule/rule.module').then(b=> b.RuleModule)
      },
      {
        path:'device', loadChildren:()=> import('./libs/device/device.module').then( b=> b.DeviceModule)
      },
      {
        path:'reports', loadChildren:()=> import('./libs/reports/reports.module').then( b => b.ReportsModule)
      },
      {
        path: 'audits', loadChildren:()=> import('./libs/audits/audits.module').then( a => a.AuditsModule)
      },
      {
        path: 'charts', loadChildren:()=> import('./libs/charts/charts.module').then( a => a.ChartsModule)
      }

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }





