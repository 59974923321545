import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { DefaultService } from './default.service';

@Injectable()
export class ApiService extends DefaultService<any> {
  constructor(httpClient: HttpClient, @Inject('BaseUrl') baseUrl: string) {
    super(httpClient, baseUrl);
  }
}
